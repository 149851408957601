import { IAction } from "../../../../shared/interfaces/common";
import { IGetDraftCartsRequest, ICreateOnBehalfOrderRequest, IClearCartRequest, IGetCartRequest, ISaveCartRequest, IAddNewCartRequest } from "../interfaces/orderOnBehalf.type";

export enum OrderOnBehalfActionTypes {
  GET_DRAFTCARTS = "[OrderOnBehalf] GET_DRAFTCARTS",
  GET_DRAFTCARTS_SUCCESS = "[OrderOnBehalf] GET_DRAFTCARTS_SUCCESS",
  GET_DRAFTCARTS_FAIL = "[OrderOnBehalf] GET_DRAFTCARTS_FAIL",

  CREATE_ONBEHALFORDER = "[OrderOnBehalf] CREATE_ONBEHALFORDER",
  CREATE_ONBEHALFORDER_SUCCESS = "[OrderOnBehalf] CREATE_ONBEHALFORDER_SUCCESS",
  CREATE_ONBEHALFORDER_FAIL = "[OrderOnBehalf] CREATE_ONBEHALFORDER_FAIL",

  ADD_NEWCART = "[OrderOnBehalf] ADD_NEWCART",
  ADD_NEWCART_SUCCESS = "[OrderOnBehalf] ADD_NEWCART_SUCCESS",
  ADD_NEWCART_FAIL = "[OrderOnBehalf] ADD_NEWCART_FAIL",

  SAVE_CART = "[OrderOnBehalf] SAVE_CART",
  SAVE_CART_SUCCESS = "[OrderOnBehalf] SAVE_CART_SUCCESS",
  SAVE_CART_FAIL = "[OrderOnBehalf] SAVE_CART_FAIL",

  CLEAR_CART = "[OrderOnBehalf] CLEAR_CART",
  CLEAR_CART_SUCCESS = "[OrderOnBehalf] CLEAR_CART_SUCCESS",
  CLEAR_CART_FAIL = "[OrderOnBehalf] CLEAR_CART_FAIL",

  GET_CART = "[OrderOnBehalf] GET_CART",
  GET_CART_SUCCESS = "[OrderOnBehalf] GET_CART_SUCCESS",
  GET_CART_FAIL = "[OrderOnBehalf] GET_CART_FAIL",

}
// Get Draft Carts
export const getDraftCarts = (payload: IGetDraftCartsRequest): IAction<any> => ({
  type: OrderOnBehalfActionTypes.GET_DRAFTCARTS,
  payload,
});

export const getDraftCartsSuccess = (payload?: any): IAction<any> => ({
  type: OrderOnBehalfActionTypes.GET_DRAFTCARTS_SUCCESS,
  payload,
});

export const getDraftCartsFail = (error: any): IAction<any> => ({
  type: OrderOnBehalfActionTypes.GET_DRAFTCARTS_FAIL,
  error,
});

// Create Order

export const createOnBehalfOrder = (payload: ICreateOnBehalfOrderRequest): IAction<any> => ({
  type: OrderOnBehalfActionTypes.CREATE_ONBEHALFORDER,
  payload,
});

export const createOnBehalfOrderSuccess = (payload?: any): IAction<any> => ({
  type: OrderOnBehalfActionTypes.CREATE_ONBEHALFORDER_SUCCESS,
  payload,
});

export const createOnBehalfOrderFail = (error: any): IAction<any> => ({
  type: OrderOnBehalfActionTypes.CREATE_ONBEHALFORDER_FAIL,
  error,
});

// Save Cart

export const saveCart = (payload: ISaveCartRequest): IAction<any> => ({
  type: OrderOnBehalfActionTypes.SAVE_CART,
  payload,
});

export const saveCartSuccess = (payload?: any): IAction<any> => ({
  type: OrderOnBehalfActionTypes.SAVE_CART_SUCCESS,
  payload,
});

export const saveCartFail = (error: any): IAction<any> => ({
  type: OrderOnBehalfActionTypes.SAVE_CART_FAIL,
  error,
});


// Clear Cart

export const clearCart = (payload: IClearCartRequest): IAction<any> => ({
  type: OrderOnBehalfActionTypes.CLEAR_CART,
  payload,
});

export const clearCartSuccess = (payload?: any): IAction<any> => ({
  type: OrderOnBehalfActionTypes.CLEAR_CART_SUCCESS,
  payload,
});

export const clearCartFail = (error: any): IAction<any> => ({
  type: OrderOnBehalfActionTypes.CLEAR_CART_FAIL,
  error,
});

// Get Cart
export const getCart = (payload: IGetCartRequest): IAction<any> => ({
  type: OrderOnBehalfActionTypes.GET_CART,
  payload,
});

export const getCartSuccess = (payload?: any): IAction<any> => ({
  type: OrderOnBehalfActionTypes.GET_CART_SUCCESS,
  payload,
});

export const getCartFail = (error: any): IAction<any> => ({
  type: OrderOnBehalfActionTypes.GET_CART_FAIL,
  error,
});
// Add New Cart

export const addNewCart = (payload: IAddNewCartRequest): IAction<any> => ({
  type: OrderOnBehalfActionTypes.ADD_NEWCART,
  payload,
});

export const addNewCartSuccess = (payload?: any): IAction<any> => ({
  type: OrderOnBehalfActionTypes.ADD_NEWCART_SUCCESS,
  payload,
});

export const addNewCartFail = (error: any): IAction<any> => ({
  type: OrderOnBehalfActionTypes.ADD_NEWCART_FAIL,
  error,
});



