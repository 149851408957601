import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { GetDetailErrorUseCase } from "../../../../domain/usecases/products";
import { getDetailErrorFail, getDetailErrorSuccess, MitsubishiActionTypes } from "../../actions/mitsubishi/mitsubishi.action";

export const getMitsubishiDetailErrorEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(MitsubishiActionTypes.GET_DETAIL_ERROR),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetDetailErrorUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(getDetailErrorSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getDetailErrorFail(error.message));
                    obs.complete();
                });
            });
        })
    );


