import { combineReducers } from "@reduxjs/toolkit";
import { saveNotificationReducer } from "./saveNotificationReducer";
import { savePartialNotificationReducer } from "./savePartialNotificationReducer";
import { registerPriceChangesReducer } from "./registerPriceChangesReducer";
import { pushNotificationReducer } from "./pushNotificationReducer";
import { searchBrandsReducer } from "./searchBrandsReducer";
import { searchBannersReducer } from "./searchBannersReducer";
import { searchNotificationsReducer } from "./searchNotificationsReducer";
import { searchPriceChangesReducer } from "./searchPriceChangesReducer";
import { getCategoriesReducer } from "./getCategoriesReducer";
import { searchTagsReducer } from "./searchTagsReducer";
import { deleteTagsReducer } from "./deleteTagsReducer";
import { addTagsForSakayaReducer } from "./addTagsForSakayaReducer";
import { updateTagForSakayaReducer } from "./updateTagForSakayaReducer";
import { addTagForCustomerReducer } from "./addTagForCustomerReducer";
import { deleteTagForCustomerReducer } from "./deleteTagForCustomerReducer";
import { importCustomerTagsReducer } from "./importCustomerTagsReducer";
import { populateAddressReducer } from "./populateAddressReducer";
import { getOrderOfProductReducer } from "./getOrderOfProductReducer";
import { getPriceChangeLinesReducer } from "./getPriceChangeLinesReducer";
import { uploadPriceNotificationReducer } from "./uploadPriceNotificationReducer";
import { importNotificationHistoryReducer } from "./importNotificationHistoryReducer";
import { downloadDraftToFileReducer } from "./downloadDraftToFileReducer";
import { getDraftRecordReducer } from "./getDraftRecordReducer";
import { getNotificationDetailReducer } from "./getNotificationDetailReducer";
import { deleteDraftRecordReducer } from "./deleteDraftRecordReducer";
import { deleteNotificationsReducer } from "./deleteNotificationsReducer";
import { updateNotificationStatusReducer } from "./updateNotificationStatusReducer";
import { searchDiscontinuedReducer } from "./searchDiscontinuedReducer";
import { searchReadyDiscontinuedReducer } from "./searchReadyDiscontinuedReducer";
import { getDiscontinuedProductsReducer } from "./getDiscontinuedProductsReducer";
import { historyDiscontinuedProductsReducer } from "./historyDiscontinuedProductsReducer";
import { countWarningProductsReducer } from "./countWarningProductsReducer";
import { createDiscontinuedProductsReducer } from "./createDiscontinuedProductsReducer";
import { uploadDiscontinuedProductsReducer } from "./uploadDiscontinuedProductsReducer";
import { updateDiscontinueLineReducer } from "./updateDiscontinueLineReducer";
import { deleteDiscontinueLinesReducer } from "./deleteDiscontinueLinesReducer";
import { savePromotionReducer } from "./savePromotionReducer";
import { calculateAnnouncementTargetReducer } from "./calculateAnnouncementTargetReducer";
import { downloadDiscontinueLinesReducer } from "./downloadDiscontinueLinesReducer";
import { getSegmentsReducer } from "./getSegmentsReducer";
import { addSegmentReducer } from "./addSegmentReducer";
import { calculateAudienceSizeReducer } from "./calculateAudienceSizeReducer";
import { deleteSegmentReducer } from "./deleteSegmentReducer";
import { settingNotificationsReducer } from "./settingNotificationsReducer";
import { getSettingNotificationsReducer } from "./getSettingNotificationsReducer";

export const notificationReducer = combineReducers({
    updateDiscontinueLineReducer,
    deleteDiscontinueLinesReducer,
    savePromotionReducer,
    calculateAnnouncementTargetReducer,
    downloadDiscontinueLinesReducer,
    saveNotificationReducer,
    savePartialNotificationReducer,
    registerPriceChangesReducer,
    pushNotificationReducer,
    searchBrandsReducer,
    searchBannersReducer,
    searchNotificationsReducer,
    searchPriceChangesReducer,
    getCategoriesReducer,
    searchTagsReducer,
    deleteTagsReducer,
    addTagsForSakayaReducer,
    updateTagForSakayaReducer,
    addTagForCustomerReducer,
    deleteTagForCustomerReducer,
    importCustomerTagsReducer,
    populateAddressReducer,
    getOrderOfProductReducer,
    getPriceChangeLinesReducer,
    uploadPriceNotificationReducer,
    importNotificationHistoryReducer,
    downloadDraftToFileReducer,
    getDraftRecordReducer,
    getNotificationDetailReducer,
    deleteDraftRecordReducer,
    deleteNotificationsReducer,
    updateNotificationStatusReducer,
    searchDiscontinuedReducer,
    searchReadyDiscontinuedReducer,
    getDiscontinuedProductsReducer,
    historyDiscontinuedProductsReducer,
    countWarningProductsReducer,
    createDiscontinuedProductsReducer,
    uploadDiscontinuedProductsReducer,
    getSegmentsReducer,
    addSegmentReducer,
    calculateAudienceSizeReducer,
    deleteSegmentReducer,
    settingNotificationsReducer,
    getSettingNotificationsReducer
});