import { IAction, IState } from "../../../../shared/interfaces/common";
import { OrderOnBehalfActionTypes } from "../../actions/orderonbehalf/orderonbehalf.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = ReducerHelper.baseReducer();

export const createOnBehalfOrderReducer = (state = initialState, action: IAction<any>) => {
    switch (action.type) {
        case OrderOnBehalfActionTypes.CREATE_ONBEHALFORDER: {
            return ReducerHelper.baseProcessRequest(state, action);
        }
        case OrderOnBehalfActionTypes.CREATE_ONBEHALFORDER_SUCCESS: {
            return ReducerHelper.baseProcessSuccess(state, action);
        }
        case OrderOnBehalfActionTypes.CREATE_ONBEHALFORDER_FAIL: {
            return ReducerHelper.baseProcessFailed(state, action);
        }
     
        default: {
            return state;
        }
    }
}