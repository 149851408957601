import { IAction } from "../../../../shared/interfaces/common";
import { IGetFileMitsubishiPayload, IImportFilesHistoryPayload } from "../interfaces/mitsubishi.types";

// Mitsubishi Action Types
export enum MitsubishiActionTypes {
  IMPORT_MITSUBISHI_FILES = "[Mitsubishi] IMPORT_MITSUBISHI_FILES",
  IMPORT_MITSUBISHI_FILES_SUCCESS = "[Mitsubishi] IMPORT_MITSUBISHI_FILES_SUCCESS",
  IMPORT_MITSUBISHI_FILES_FAIL = "[Mitsubishi] IMPORT_MITSUBISHI_FILES_FAIL",

  GET_IMPORT_MITSUBISHI_FILES_HISTORY = "[Mitsubishi] GET_IMPORT_MITSUBISHI_FILES_HISTORY",
  GET_IMPORT_MITSUBISHI_FILES_HISTORY_SUCCESS = "[Mitsubishi] GET_IMPORT_MITSUBISHI_FILES_HISTORY_SUCCESS",
  GET_IMPORT_MITSUBISHI_FILES_HISTORY_FAIL = "[Mitsubishi] GET_IMPORT_MITSUBISHI_FILES_HISTORY_FAIL",

  GET_FILE_AWS = "[Mitsubishi] GET_FILE_AWS",
  GET_FILE_AWS_SUCCESS = "[Mitsubishi] GET_FILE_AWS_SUCCESS",
  GET_FILE_AWS_FAIL = "[Mitsubishi] GET_FILE_AWS_FAIL",

  GET_DETAIL_ERROR = "[Mitsubishi] GET_DETAIL_ERROR",
  GET_DETAIL_ERROR_SUCCESS = "[Mitsubishi] GET_DETAIL_ERROR_SUCCESS",
  GET_DETAIL_ERROR_FAIL = "[Mitsubishi] GET_DETAIL_ERROR_FAIL",
}

//import mitsubishi files
export const importMitsubishiFiles = (payload: any): IAction<any> => ({
  type: MitsubishiActionTypes.IMPORT_MITSUBISHI_FILES,
  payload
});

export const importFilesSuccess = (payload?: any): IAction<any> => ({
  type: MitsubishiActionTypes.IMPORT_MITSUBISHI_FILES_SUCCESS,
  payload,
});

export const importFilesFail = (error: any): IAction<any> => ({
  type: MitsubishiActionTypes.IMPORT_MITSUBISHI_FILES_FAIL,
  error,
});

export const getImportFileHistory = (payload: IImportFilesHistoryPayload): IAction<any> => ({
  type: MitsubishiActionTypes.GET_IMPORT_MITSUBISHI_FILES_HISTORY,
  payload
});

export const getImportFileHistorySuccess = (payload?: any): IAction<any> => ({
  type: MitsubishiActionTypes.GET_IMPORT_MITSUBISHI_FILES_HISTORY_SUCCESS,
  payload,
});

export const getImportFileHistoryFail = (error: any): IAction<any> => ({
  type: MitsubishiActionTypes.GET_IMPORT_MITSUBISHI_FILES_HISTORY_FAIL,
  error,
});

export const getFileImportMitsubishi = (payload: IGetFileMitsubishiPayload): IAction<any> => ({
  type: MitsubishiActionTypes.GET_FILE_AWS,
  payload
});

export const getFileImportMitsubishiSuccess = (payload?: any): IAction<any> => ({
  type: MitsubishiActionTypes.GET_FILE_AWS_SUCCESS,
  payload,
});

export const getFileImportMitsubishiFail = (error: any): IAction<any> => ({
  type: MitsubishiActionTypes.GET_FILE_AWS_FAIL,
  error,
});

export const getDetailError = (payload?: any): IAction<any> => ({
  type: MitsubishiActionTypes.GET_DETAIL_ERROR,
  payload,
});

export const getDetailErrorSuccess = (payload?: any): IAction<any> => ({
  type: MitsubishiActionTypes.GET_DETAIL_ERROR_SUCCESS,
  payload,
});

export const getDetailErrorFail = (error: any): IAction<any> => ({
  type: MitsubishiActionTypes.GET_DETAIL_ERROR_FAIL,
  error,
});