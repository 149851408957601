import {IUseCase} from '../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../models/response';
import ProductRepository from '../../../data/repository/product';
import ValidationResult from '../../../models/validationResult';
import { IGetProductsPayload } from '../../../presentation/redux/actions/products/products.types';
import { notifyError, notifySuccess } from '../../../utils/toast';
export default class ImportPricesCase implements IUseCase {
    private productRepository: ProductRepository;

    /**
     *
     */
    constructor(public payload: any) {
        this.productRepository = new ProductRepository();
    }


    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            this.productRepository.importPricesRepository(this.payload).then((response: any) => {
                if (response.data.success) {
                    resolve(response.data);
                    if(response.data.data.totalValidRecord > 0){
                        notifySuccess('価格のインポートに成功しました!');
                    }
                    else{
                        notifyError('価格のインポートに失敗しました。有効なデータを含むワークシートがありませんでした。');
                    }
                    
                } else {
                    if(response.data.data?.errors?.length > 0)
                        notifyError("データのアップロードに失敗しました。\nテンプレートまたはファイル形式をご確認ください");
                    else
                        notifyError("失敗");
                    reject(ResponseModel.createError(500, response.data.errorMessage));
                }
            }).catch(reject);
        });

    };

}
