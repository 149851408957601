import { combineReducers } from "@reduxjs/toolkit";
import { getCustomerReducer } from "./getCustomerReducer";
import { getCustomersReducer } from "./getCustomersReducer";
import { getCustomerGroupsReducer } from "./getCustomerGroupsReducer";
import { exportCustomersReducer } from "./exportCustomersReducer";
import { createAddressReducer } from "./createAddressReducer";
import { importHorecaInformationReducer } from "./importHorecaInformationReducer";
import { exportQRCodesReducer } from "./exportQRCodesReducer";
import { pauseOrResumeCustomerReducer } from "./pauseOrResumeCustomerReducer";
import { updateCustomerHidePriceReducer } from "./updateCustomerHidePricesReducer";
import { importHidePriceReducer } from "./importHidePriceReducer";
import { getCustomerHidePriceUploadHistoryReducer } from "./getCustomerHidePriceUploadHistoryReducer";
import { getCustomerHidePriceFileReducer } from "./getCustomerHidePriceFileReducer";
import { updateCustomerAddressReducer } from "./updateCustomerAddressReducer";
import { populateAddressReducer } from "./populateAddressReducer";

export const customersReducer = combineReducers({
  getCustomersReducer,
  getCustomerReducer,
  getCustomerGroupsReducer,
  exportCustomersReducer,
  createAddressReducer,
  pauseOrResumeCustomerReducer,
  importHorecaInformationReducer,
  exportQRCodesReducer,
  updateCustomerHidePriceReducer,
  importHidePriceReducer,
  getCustomerHidePriceUploadHistoryReducer,
  getCustomerHidePriceFileReducer,
  updateCustomerAddressReducer,
  populateAddressReducer
});
