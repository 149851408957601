import { combineReducers } from "@reduxjs/toolkit";
import { getSellingPricesReducer } from "./getSellingPricesReducer";
import { getSellingPricesWithTopCatalogReducer } from "./getSellingPricesWithTopCatalogReducer";
import { getDiscontinuedPricesReducer } from "./getDiscontinuedPricesReducer";
import { searchProductsReducer } from "./searchProductsReducer";
import { getUnSellingPricesReducer } from "./getUnSellingPricesReducer";
import { getPausedProductsReducer } from "./getPausedProductsReducer";
import { exportPricesReducer } from "./exportPricesReducer";
import { exportPricesV2Reducer } from "./exportPricesV2Reducer";
import { exportVariantsReducer } from "./exportVariantsReducer";
import { importVariantsReducer } from "./importVariantsReducer";
import { getImportPricesHistoryReducer } from "./getImportPricesHistoryReducer";
import { getFileImportPriceReducer } from "./getFileImportPriceReducer";
import { importPricesReducer } from "./importPricesReducer";
import { updatePriceReducer } from "./updatePriceReducer";
import { setActivePriceReducer } from "./setActivePriceReducer";
import { setDiscontinuedPriceReducer } from "./setDiscontinuedPriceReducer";
import { getActiveCustomersReducer } from "./getActiveCustomersReducer";
import { getOverrideDisplayReducer } from "./getOverrideDisplayReducer";
import { createPriceReducer } from "./createPriceReducer";
import { getPriceDetailReducer } from "./getPriceDetailReducer";
import { getProductSettingsReducer } from "./getProductSettingsReducer";
import { getVariantDetailReducer } from "./getVariantDetailReducer";
import { updatePromotionReducer } from "./updatePromotionReducer";
import { deletePriceReducer } from "./deletePriceReducer";
import { getPriceCustomerGroupsReducer } from "./getPriceCustomerGroupsReducer";
import { createCustomerGroupPriceReducer } from "./createCustomerGroupPriceReducer";
import { settingCustomProductReducer } from "./settingCustomProductReducer";
import { deleteCustomerGroupPriceReducer } from "./deleteCustomerGroupPriceReducer";
import { updateCustomerGroupPriceReducer } from "./updateCustomerGroupPriceReducer";
import { disablePriceForGroupReducer } from "./disablePriceForGroupReducer";
import { setProductVisibleReducer } from "./setProductVisibleReducer";
import { setOverrideDisplayReducer } from "./setOverrideDisplayReducer";
import { updateProductPriorityReducer } from "./updateProductPriorityReducer";
import { setColorVariantReducer } from "./setColorVariantReducer";
import { importAlarmProductsReducer } from "./importAlarmProductsReducer";
import { getProductsUploadHistoryReducer } from "./getProductsUploadHistoryReducer";
import { getAlarmProductsFileReducer } from "./getAlarmProductsFileReducer";
import { getDetailErrorReducer } from "./getDetailErrorReducer";

export const productsReducer = combineReducers({
    getSellingPricesReducer,
    getSellingPricesWithTopCatalogReducer,
    getDiscontinuedPricesReducer,
    searchProductsReducer,
    getUnSellingPricesReducer,
    getPausedProductsReducer,
    exportPricesReducer,
    exportPricesV2Reducer,
    exportVariantsReducer,
    importVariantsReducer,
    getImportPricesHistoryReducer,
    getFileImportPriceReducer,
    importPricesReducer,
    updatePriceReducer,
    setActivePriceReducer,
    setDiscontinuedPriceReducer,
    getActiveCustomersReducer,
    getOverrideDisplayReducer,
    createPriceReducer,
    getPriceDetailReducer,
    getProductSettingsReducer,
    getVariantDetailReducer,
    updatePromotionReducer,
    deletePriceReducer,
    getPriceCustomerGroupsReducer,
    createCustomerGroupPriceReducer,
    settingCustomProductReducer,
    deleteCustomerGroupPriceReducer,
    updateCustomerGroupPriceReducer,
    disablePriceForGroupReducer,
    setProductVisibleReducer,
    setOverrideDisplayReducer,
    updateProductPriorityReducer,
    setColorVariantReducer,
    importAlarmProductsReducer,
    getProductsUploadHistoryReducer,
    getAlarmProductsFileReducer,
    getDetailErrorReducer
});