import {IUseCase} from '../../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../../models/response';
import OrderOnBehalfRepository from '../../../../data/repository/orderonbehalf';
import ValidationResult from '../../../../models/validationResult';
import { IGetCartRequest } from '../../../../presentation/redux/actions/interfaces/orderOnBehalf.type';

export default class GetCartUseCase implements IUseCase {
    private orderOnBehalfRepository: OrderOnBehalfRepository;
    /**
     *
     */
    constructor(public payload: IGetCartRequest) {
        this.orderOnBehalfRepository = new OrderOnBehalfRepository();
    }

    validate = (): ValidationResult => {
        return new ValidationResult(true);
    };

    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            const validationResult = this.validate();
            if (validationResult.isValid) {
                this.orderOnBehalfRepository.getCart(this.payload).then((response: any) => {
                    if (response.data.data) {
                        resolve(response.data);
                    } else {
                        reject(ResponseModel.createError(500, response.data.errorMessage));
                    }
                }).catch(reject);
            }
        });

    };
}
