import { combineReducers } from "redux";
import { searchTempProductsReducer } from "./searchTempProductsReducer";
import { deleteTempProductsReducer } from "./deleteTempProductsReducer";
import { searchProductsReducer } from "./searchProductsReducer";
import { saveTempProductsReducer } from "./saveTempProductsReducer";
import { getCategoriesReducer } from "./getCategoriesReducer";
import { getBrandsReducer } from "./getBrandsReducer";
import { importBatchProductsReducer } from "./importBatchProductsReducer";
import { importProductsHistoriesReducer } from "./importProductsHistoriesReducer";

export const tempProductReducer = combineReducers({
  searchTempProductsReducer,
  deleteTempProductsReducer,
  searchProductsReducer,
  saveTempProductsReducer,
  getCategoriesReducer,
  getBrandsReducer,
  importBatchProductsReducer,
  importProductsHistoriesReducer
});
