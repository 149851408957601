import { combineReducers } from "@reduxjs/toolkit";
import { getDraftCartsReducer } from "./getDraftCartsReducer";
import { clearCartReducer } from "./clearCartReducer";
import { getCartReducer } from "./getCartReducer";
import { addNewCartReducer } from "./addNewCartReducer";
import { saveCartReducer } from "./saveCartReducer";
import { createOnBehalfOrderReducer } from "./createOnBehalfOrderReducer";
export const orderOnBehalfReducer = combineReducers({
  getDraftCartsReducer,
  clearCartReducer,
  getCartReducer,
  addNewCartReducer,
  saveCartReducer,
  createOnBehalfOrderReducer
});
