import { ReturnStatus } from "../../presentation/redux/actions/interfaces/order.types";
import {
  DayOfWeekEN,
  DayOfWeekJP,
} from "../../presentation/redux/actions/interfaces/users.types";
import DefaultRewardImage from "../../assets/img/default-reward-image.png";
import { campaignTypes, conditionTypes, promotionTypes } from '../../presentation/modules/promotions/SalesPromotionFormProvider';

export const ActionStatus = {
  NONE: "none",
  FETCHING: "fetching",
  REFRESHING: "refreshing",
  DONE: "done",
};

export const SearchCriteriaConditionTypes = {
  Equal: "eq",
  In: "in",
  GreaterThan: "gt",
  LessThan: "lt",
  Between: "between",
  Finset: "finset",
  Like: "like",
};
export const DEFAULT_SECTION_ID = "default";

export const SortDirections = {
  Ascending: "ASC",
  Descending: "DESC",
};

export const PageSize = {
  Default: 20,
};

export const Gender = {
  Male: 1,
  Female: 2,
};

export const DateTimeFormat = {
  FullDateTime: "DD/MM/YYYY hh:mm:ss",
  DateTimeAmPm: "DD/MM/YYYY hh A",
  DateTime24h: "DD/MM/YYYY HH:mm",
  Time: "hh:mm:ss",
  FullDate: "DD/MM/YYYY",
  TimeHourMinPM: "HH:mm A",
  FullDateDash: "DD-MM-YYYY",
  APIFormat: "YYYY-MM-DD HH:mm:ss",
};

export const PriceFormat = {
  Default: "0,0",
};

export const LimitNumber = {
  MaxAddress: 3,
};
export const LANGUAGE = {
  ENGLISH: "English",
  CHINESE: "Chinese",
  JAPANESE: "Japanese",
};

export const AdminRoles = ["MAINTAINER", "SUPER_ADMIN", "ADMIN", "HR"];

export enum HidePricesOptions {
  NotSet = "NotSet",
  AllPrices = "AllPrices",
  CustomPricesOnly = "CustomPricesOnly",
  DefaultPricesOnly = "DefaultPricesOnly"
}

export const breakpoints = {
  values: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1536,
  },
};

export const debounceDelay = 500;

export const dayOfWeekMap = {
  Monday: "月曜日",
  Tuesday: "火曜日",
  Wednesday: "水曜日",
  Thursday: "木曜日",
  Friday: "金曜日",
  Saturday: "土曜日",
  Sunday: "日曜日",
} as const;

export const dayOfWeekOffMap = {
  Monday: "offMondayLastWeek",
  Tuesday: "offTuesdayLastWeek",
  Wednesday: "offWednesdayLastWeek",
  Thursday: "offThursdayLastWeek",
  Friday: "offFridayLastWeek",
  Saturday: "offSaturdayLastWeek",
  Sunday: "offSundayLastWeek",
} as const;

export const dayOfWeekEntries = Object.entries(dayOfWeekMap) as [
  DayOfWeekEN,
  DayOfWeekJP
][];

export const mimes = {
  csv: "text/csv;charset=utf-8;",
  pdf: "application/pdf",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const returnStatus: Record<
  ReturnStatus,
  { background: string; color: string; name: string }
> = {
  1: { background: "#FDE3FF", color: "#121A1E", name: "未処理" }, // untreated
  2: { background: "#C6DA77", color: "#121A1E", name: "完了済" }, // completed
  3: { background: "#FF6868", color: "#121A1E", name: "却下" }, // rejected
  4: { background: "#FF8e8e", color: "white", name: "一部却下" }, // partially rejected
};

export const weeksOfMonthMap = {
  Week1: "1週目",
  Week2: "2週目",
  Week3: "3週目",
  Week4: "4週目",
  Week5: "5週目",
  LastWeek: "最後の週",
} as const;

export const zoneOptions = [
  { id: "A", name: "A" },
  { id: "B", name: "B" },
];

export const ERRORS_CSV_DATA = [["No.", "エラーの詳細"]];

// TODO: translations
export const emailStatus = {
  1: { background: "#EEEEEE", color: "#121A1E", name: "下書き" }, // draft
  2: { background: "#E9ECFD", color: "#121A1E", name: "予約済み" }, // scheduled
  3: { background: "#F7D589", color: "#121A1E", name: "停止中" }, // stopped or paused
  4: { background: "#C2D97C", color: "#121A1E", name: "送信済み" }, // sent
} as const;

// TODO: translations
export const campaignStatus = {
  0: {
    background: "#EEEEEE",
    color: "#121A1E",
    name: "下書き",
    actions: "delete, edit, view",
  }, // draft
  1: {
    background: "#E9ECFD",
    color: "#121A1E",
    name: "予約済み",
    actions: "delete, edit, pause, view",
  }, // scheduled
  2: {
    background: "#FDF3DC",
    color: "#121A1E",
    name: "開催中",
    actions: "edit, pause, view",
  }, // in progress
  3: {
    background: "#F7D589",
    color: "#121A1E",
    name: "停止中",
    actions: "delete, view",
  }, // Stopped
  4: {
    background: "#C2D97C",
    color: "#121A1E",
    name: "終了",
    actions: "view",
  }, // completed
} as const;

export const placeholderURL = DefaultRewardImage;

export const mapCampaignType = new Map();
mapCampaignType.set(0, campaignTypes[0].label); // Sample & Gifts
mapCampaignType.set(1, campaignTypes[1].label); // Discount
mapCampaignType.set(2, campaignTypes[2].label); // Mileages & Loyalty

export const mapPromotionType = new Map();
mapPromotionType.set(0, promotionTypes[0].label); // Free sample or material
mapPromotionType.set(1, promotionTypes[1].label); // Order discount
mapPromotionType.set(2, promotionTypes[2].label); // Product discount
mapPromotionType.set(3, promotionTypes[3].label); // Free shipping

export const mapConditionType = new Map();
mapConditionType.set(0, conditionTypes[0].label); // Order includes product"
mapConditionType.set(1, conditionTypes[1].label); // Order includes product brand / maker"
mapConditionType.set(2, conditionTypes[2].label); // Order include product tag"
mapConditionType.set(3, conditionTypes[3].label); // Cart total"

export enum ErrorCategory {
  Price,
  Notification,
  Alarm,
  HidePrice,
  Flyer,
  DeliveryTime,
  DeliveryHoliday,
  Invoice,
  MasterProduct,
  MitsubishiUpload
}
