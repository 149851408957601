import { getBaseConfig } from '../../config';
 
export const getConfig = () => {
    const mBaseConfig = getBaseConfig();
    if (mBaseConfig) {
        return {
            ...mBaseConfig,            
        };
    }
};
 
export const graphConfig = () => {
    return {
      endpoint: 'https://api-ap-northeast-1-cocacola-jp.hygraph.com/v2/clhkkqz4840eo01uh4lzuag6a/dev',
      domain: 'https://api-ap-northeast-1-cocacola-jp.hygraph.com/v2/clhkkqz4840eo01uh4lzuag6a/dev',
      accessToken:'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2ODM5MTA1OTQsImF1ZCI6WyJodHRwczovL2FwaS1hcC1ub3J0aGVhc3QtMS1jb2NhY29sYS1qcC5oeWdyYXBoLmNvbS92Mi9jbGhra3F6NDg0MGVvMDF1aDRsenVhZzZhL2RldiIsIm1hbmFnZW1lbnQtbmV4dC5ncmFwaGNtcy5jb20iXSwiaXNzIjoiaHR0cHM6Ly9tYW5hZ2VtZW50LmdyYXBoY21zLmNvbS8iLCJzdWIiOiI2ZmQwYzZlYi1mMDA3LTQwYTMtOGNjYy1hOTEzZjk4ODcwZTgiLCJqdGkiOiJjbDA5ZDF2ODY3Y3ZvMDF6MzhicmdlOTR0In0.zMQ-RxdMVYHeqa38nouuyAvbnEPPGXBN42RxA9sAVN99-ZMWegPB41mlihxBG9EjHfgFZ9HOcgXfvr0x209iX7VVHjAlEm5dkIQnzFznY-k5jruU0B5JpxTk1JvSfdkkzo8wbOWAf1xeJyASjLwXtfoxTppIqMXJR7iU7L10RjRq5hGhpUhEREXanbsub7lc-1JJMKm5oy_KE40d5PQTFigcszKhyoGqFGtVfVdZHEELkqBIYFa4XOTL2s1cstGxTBqfSADuaRv3MxtlG8vLZCrIQdeHGUPRNjxXu5Q4saRwBsyFZxBMA2W0S2Cb001mLe2C9ZWPF4xm9JhHlQKJ4TmQMr67jFUgob1d3flYm73YJjE9JZf0RB6XXq1E9XkGLnNHll9ctyNxOP19oVwbZRtZDwMx3StRBxdxc5-XiBHiT83PKSuUVfkr_5n9XJ31s8gFBLpzw95FF3gzTyzO1jp5iBpkO3U2YNobs_FACvFBInSZUxI0Mmp8mfTut7egzFF9bUDCMs2fiqFcMKSaD70Cn47e6USSqvqiV1UK7_5GGFj2zsBOc-I_EQcKMRtfs41iMH812hT1ftuLPfdHILW07cbhisrpl4ThWBY5M94DVEIHkbIGT1U9iuJ2rl3gJ8TwS_XvNeYu7YZ_XjMbi3As8QEWYnULHfzHzSPqm70',
      googleApiKey: 'AIzaSyDe2Kk6i-VPLHmGq-0_RH7JYk1QlaxSYMsI',
      sitecoreApiKey: '7A3E0468-E733-4F81-AABE-2BC0E797E5E0',
    };
  };