import Type from '../../type';
import {getApiController} from '..';
import {IResource} from '../../../../../shared/interfaces/common/resource';
import { IGetFileMitsubishiPayload, IImportFilesHistoryPayload } from '../../../../../presentation/redux/actions/interfaces/mitsubishi.types';

const ImportFiles = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/mitsubishi/v1.0/ImportMitsubishiFiles`
});

const ImportFilesHistory = (payload: IImportFilesHistoryPayload,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/mitsubishi/v1.0/ImportMitsubishiFilesHistory?page=${payload.page}&type=${payload.type ?? ''}&pageSize=${payload.pageSize}&endDate=${payload.endDate}&startDate=${payload.startDate}&sakayaId=${payload.sakayaId}&id=${payload.id ?? ''}&ignoreLogs=true`
});

const GetFileImportMitsubishi = (payload: IGetFileMitsubishiPayload,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/mitsubishi/v1.0/GetFileImportMitsubishi?fileName=${encodeURIComponent(payload.fileName)}&fileType=${payload.fileType ?? 0}`
});

const GetFileDetailError = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/GetDetailError?id=${payload.id}&category=${payload.category}`
});

export default {
    ImportFiles,
    ImportFilesHistory,
    GetFileImportMitsubishi,
    GetFileDetailError
};

