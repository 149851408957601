import Type from '../../type';
import {getApiController} from '..';
import {IResource} from '../../../../../shared/interfaces/common/resource';
import { IAddNewCartRequest, IClearCartRequest, IGetCartRequest } from '../../../../../presentation/redux/actions/interfaces/orderOnBehalf.type';

const GetDraftCarts = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/orderonbehalf/v1.0/GetAllDrafts`
});

const CreateOnBehalfOrder = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/orderonbehalf/v1.0/CreateOrder`
});

const AddNewCart = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/orderonbehalf/v1.0/Add`
});

const SaveCart = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/orderonbehalf/v1.0/Update`
});

const ClearCart = (payload: IClearCartRequest,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/orderonbehalf/v1.0/Delete?cartId=${payload.id}`
});
const GetCart = (payload: IGetCartRequest,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/orderonbehalf/v1.0/Get?cartId=${payload.id}`
});
const resources = {
    GetDraftCarts,
    CreateOnBehalfOrder,
    AddNewCart,
    SaveCart,
    ClearCart,
    GetCart
};

export default resources;
