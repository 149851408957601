import { IGetFileMitsubishiPayload, IImportFilesHistoryPayload } from '../../../presentation/redux/actions/interfaces/mitsubishi.types';
import MitsubishiCloud from './cloud';

export default class MitsubishiRepository {
    public importFilesHistoryRepository = (payload: IImportFilesHistoryPayload) => {
        return MitsubishiCloud.importFilesHistory(payload);
    };
    
    public importFilesRepository = (payload: any) => {
        return MitsubishiCloud.importFiles(payload);
    };

    public getFileImportMitsubishi = (payload: IGetFileMitsubishiPayload) => {
        return MitsubishiCloud.getFileImportMitsubishi(payload);
    };

    public getDetailErrorRepository = (payload: any) => {
        return MitsubishiCloud.getFileDetailError(payload);
	};
	
}
