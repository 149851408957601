import { IGetDraftCartsRequest, ICreateOnBehalfOrderRequest, IAddNewCartRequest,IGetCartRequest, IClearCartRequest, ISaveCartRequest} from '../../../presentation/redux/actions/interfaces/orderOnBehalf.type';
import OrderOnBehalfCloud from './cloud';

export default class OrderOnBehalfRepository {

    public getDraftCarts = (payload: IGetDraftCartsRequest) => {
        // Consider call api or get data from localStorage
        return OrderOnBehalfCloud.getDraftCarts(payload);
    };
    public createOnBehalfOrder = (payload: ICreateOnBehalfOrderRequest) => {
        // Consider call api or get data from localStorage
        return OrderOnBehalfCloud.createOnBehalfOrder(payload);
    };
    public saveCart = (payload: ISaveCartRequest) => {
        // Consider call api or get data from localStorage
        return OrderOnBehalfCloud.saveCart(payload);
    };
    public clearCart = (payload: IClearCartRequest) => {
        // Consider call api or get data from localStorage
        return OrderOnBehalfCloud.clearCart(payload);
    };
    public getCart = (payload: IGetCartRequest) => {
        // Consider call api or get data from localStorage
        return OrderOnBehalfCloud.getCart(payload);
    };
    public addNewCart = (payload: IAddNewCartRequest) => {
        // Consider call api or get data from localStorage
        return OrderOnBehalfCloud.addNewCart(payload);
    };
}
