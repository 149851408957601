import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { CustomerActionTypes, updateCustomerAddressSuccess, updateCustomerAddressFail } from "../../actions/customers/customer.actions";
import updateCustomerAddressUseCase from "../../../../domain/usecases/customer/updateCustomerAddress";
import { notifyError, notifySuccess } from "../../../../utils/toast";


export const updateCustomerAddressEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(CustomerActionTypes.UPDATE_CUSTOMER_ADDRESS),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new updateCustomerAddressUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(updateCustomerAddressSuccess(data));
                    notifySuccess("Update customer information success !")
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message)
                    obs.next(updateCustomerAddressFail(error.message));
                    obs.complete();
                });
            });
        })
    );


